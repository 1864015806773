import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import RichTextProps from "./RichTextProps";

const RichText = loadable(
    (): Promise<DefaultComponent<RichTextProps>> =>
        import(/* webpackChunkName: "RichText" */ "./RichText"),
);
const RichTextLoader = (props: RichTextProps): ReactElement => (
    <RichText {...props} />
);
export default RichTextLoader;
