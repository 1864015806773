import React, { ReactElement, useContext } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import RichText from "components/RichText";
import { addEditAttributes } from "utils/episerver";
import getButtonTheme from "utils/getButtonTheme";

import { Container } from "./TextBlock.styled";
import TextBlockProps from "./TextBlockProps";

const TextBlock = ({
    content,
    displayOption = "Left",
    identifier,
    className,
    theme,
    contentTheme,
    actionTheme,
    editorialGrid = false,
    editPropertyName,
    renderElement,
    lightbox = false,
    l18n,
}: TextBlockProps): ReactElement => {
    const themeContext = useContext(ThemeContext);

    theme = theme || (themeContext && themeContext.theme) || "lightgray";
    actionTheme = actionTheme || (themeContext && themeContext.actionTheme);

    if (!actionTheme)
        switch (theme) {
            case "blue":
                actionTheme = "cyan";
                break;
            case "cyan":
                actionTheme = "blue";
                break;
            default:
                actionTheme = "blue";
                break;
        }

    const buttonTheme = getButtonTheme(
        theme || "lightgray",
        actionTheme || "blue",
        actionTheme === "black" ? "outline" : "primary",
    );

    return (
        <ThemeProvider
            theme={{
                theme: theme,
                actionTheme: actionTheme,
                buttonTheme: buttonTheme,
            }}
        >
            <Container
                id={identifier}
                key={identifier}
                className={className}
                $displayOption={displayOption}
                $editorialGrid={editorialGrid}
                {...(editPropertyName
                    ? addEditAttributes(editPropertyName)
                    : {})}
            >
                {content && (
                    <RichText
                        content={content}
                        theme={theme}
                        contentTheme={contentTheme}
                        actionTheme={actionTheme}
                        renderElement={renderElement}
                        lightbox={lightbox}
                        l18n={l18n}
                    />
                )}
            </Container>
        </ThemeProvider>
    );
};

export default React.memo(TextBlock);
